.App {
  text-align: center;
  font-family: 'Sora';
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: 'Sora';
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  background: #fefefe;
  overflow: 'scroll';
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #c4c4c4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* SCROLL SNAPPING */
/* @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
} */

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */

  .scroll-container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: auto;
    scroll-snap-type: y mandatory;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .scroll-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  section {
    scroll-snap-align: center;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */

  .scroll-container {
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

section {
  scroll-snap-align: center;
}

section {
  scroll-snap-align: center;
}

@-moz-document url-prefix() {
  section {
    scroll-snap-align: none;
  }
}

a {
  color: #fff;
  text-decoration: none;
}

.MuiDialog-paper {
  padding: 0;
  width: 100vw !important;
  margin: 1rem !important;
}

.MuiDialog-container {
  width: 100vw;
  padding: 0;
  margin: 0 !important;
}
